.course-main-content-header-block{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    top: 33px;
    position: absolute;
    right: 10px;
    z-index: 99;
}
@media screen and (min-width: 768px) {
    .course-main-content-header-block{
        position: static;
        padding-bottom: 10px;
        padding-left: 15px;
    }
}
.p-relative{
    position: relative;
}
.course-browse-btn-block{
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}
.course-browse-btn-block:hover{
    opacity: .8;
}
.course-browse-icon-container{
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--accent);
    border-radius: 50%;
}
.course-main-content-header-block span{
    color:var(--accent)
}
@media screen and (max-width: 480px) {
    .course-main-content-header-block{
        gap: 10px;
        top: -29px;
        right: 0;
        left: 19px;
        padding: 0 10px;
    }
    .course-browse-icon-container{
        width: 30px;
        height: 30px;
    }
}
.course-next-previous-btn-container{
    display: flex;
    gap: 10px;
}
.course-title-block{
    display: flex;
    align-items: center;
    gap: 10px;
}
.course-main-content-header-block{
    position: sticky;
    top: 197px;
    background: white;
}
@media screen and (max-width: 480px) {
    .main-content .inside_page{
        margin-right: unset!important;
        padding-right: unset!important;
    }
    .toolbar-div.grade, .toolbar-div.skills-div{
        padding: 0px 12px!important;
    }
    .main-content .div-block-630{
        padding-left: 10px;
        column-gap: 33px;
        border-bottom: 1px solid #d2d0d0 !important;
    }
    .course-title-top-menu-block .div-block-63{
        margin: 15px 0px 6px;
    }
}
@media screen and (max-width: 768px) {
    .main-content .course-title-top-menu-block{
        flex-wrap: wrap;
        height: auto!important;
        z-index: 9!important;
    }
    .secondary_link.sm.sklls.plus_skills{
        height: auto!important;
    }
    .course-title-top-menu-block .div-block-63{
        justify-content: flex-start!important;
    }
}
