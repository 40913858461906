.full.w-bar .right-content {
  display: flex !important;
  flex-direction: column !important;
}

@media screen and (min-width: 768px) and (max-width: 1090px) {
  .div-block-19.cert-grid {
    grid-template-columns: 1fr;
  }
}

.pr-text:after,
.pr-text-2:after,
.pr-text-3:after,
.pr-text-4:after {
  animation: blink 1s steps(5, start) infinite;
  content: '▋';
  vertical-align: baseline;
  color: #21232e;
}

[dir="ltr"] .pr-text:after,
[dir="ltr"] .pr-text-2:after,
[dir="ltr"] .pr-text-3:after,
[dir="ltr"] .pr-text-4:after {
  margin-left: 0.25rem;
}

[dir="rtl"] .pr-text:after,
[dir="rtl"] .pr-text-2:after,
[dir="rtl"] .pr-text-3:after,
[dir="rtl"] .pr-text-4:after {
  margin-right: 0.25rem;
}

.div-block-346 {
  border-radius: 2px;
}

.up {
  white-space: nowrap;
}

.text-block-189 {
  margin-bottom: 15px;
}

.div-block-345.u-p-section,
.div-block-345.bl-p-section {
  padding-top: 15px !important;
}

.div-block-352.three-dots-menu {
  height: 132px !important;
}

.text-block-203 {
  margin-bottom: 15px;
}

.div-block-357 {
  padding-bottom: 12px;
}

.form-block.s_skill.r-search.s-r.search-admin {
  margin-bottom: 15px;
}

.div-block-345.bl-p-section {
  padding: 12px;
  padding-left: 10px;
  padding-right: 10px;
}

.div-block-345.d-p-section {
  padding: 12px;
  padding-left: 10px;
  padding-right: 10px;
}

.cpc {
  margin-top: 4px !important;
}

.user-dots.active {
  background-color: #f7f9fd;
}

.d-admin-m {
  height: 36px !important;
  width: 152px !important;
}

.user-dots.active .d-admin-m {
  display: block;
}

.pm-33 {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.pm-272 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.div-block-358 {
  flex-grow: 1;
  align-items: start;
}

.chat-box.logged-in.jc-start {
  justify-content: start !important;
}

@media only screen and (max-width: 767px) {
  .text-block-167.new-chat-text,
  .text-block-186,
  .text-block-184,
  .text-block-169,
  .text-field-11.ask-input,
  .all,
  .text-block-192,
  .rel-answer.open .answer-text,
  .text-block-189,
  .textarea-2,
  .w_i,
  .create-text,
  .add-text,
  .rec-h,
  .text-field.n_text.s-f,
  .text-block-167.add-resource,
  .text-block-199,
  .text-field-12,
  .text-block-159-train,
  .text-block-196,
  .text-block-198,
  .text-block-160,
  .div-block-340-train .link-options-train,
  .text-block-165-tr,
  .text1-tr,
  .paragraph-tr,
  .text-block-163-tr,
  .text-block-86-tr,
  .text-block-86-tr,
  .instructor-name-train,
  .instructor-subtitle-train,
  .instructor-school-train,
  .text-block-165-train,
  .text-block-26,
  .text-block-197,
  .test-name-3,
  .text-block-86,
  .text-block-120,
  .text-block-86,
  .question-title-te {
    font-size: 16px !important;
  }

  .instructor-img-train {
    width: 70px !important;
    height: 70px !important;
  }

  .text-block-191,
  .text-block-190,
  .b-link {
    font-size: 14px;
  }

  .image-45 {
    height: 18px;
  }

  .content-2.r-hide {
    padding-bottom: 14px !important;
  }

  .text-block-16.p,
  .text-block-124 {
    font-size: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .sign-in-panel {
    padding-top: 49px;
  }

  .chat-box-sign-in.hidden.open {
    border: none !important;
    height: auto !important;
  }
}

.chat-box-sign-in.hidden.open {
  height: auto;
  padding-bottom: 12px;
  height: 100%;
}

[dir="ltr"] .chat-box-sign-in.hidden.open {
  margin-right: 0;
}

[dir="rtl"] .chat-box-sign-in.hidden.open {
  margin-left: 0;
}

.or-3-copy {
  text-align: center;
}

.chat-box-sign-in.hidden {
  border: 1px solid #dde1e6 !important;
}

.chat-1 .content-2 {
  padding-bottom: 0 !important;
}

.div-block-305:hover {
  background-color: #fff;
}

.related_f {
  display: none;
}

.sources_f {
  display: none;
}

.create-div .text-field {
  width: 100%;
}

.add_content_popup .div-block-333 {
  flex: inherit !important;
}

.b-link:hover {
  text-decoration: underline;
}

.div-block-347 {
  padding: 10px;
  z-index: 2222;
}

[dir="ltr"] .b-link {
  margin-right: 6px !important;
}

[dir="rtl"] .b-link {
  margin-left: 6px !important;
}

.div-block-262.last {
  padding-bottom: 3px;
}

.about_p_popup_resource .about-box-train .faq-question-wrap-2 {
  margin-bottom: 0;
}

.about_p_popup_resource .faq-question-bar-2 {
  padding-bottom: 0;
}

.link-options-train.link-options:hover {
  border-bottom: 2px solid #6cafe1;
}

.about-box-train .text-block-120 {
  color: #6cafe1;
}

.question-title-te,
.test-name-3 {
  color: #6cafe1;
}

[dir="ltr"] .link-options-train {
  padding-right: 0 !important;
}

[dir="rtl"] .link-options-train {
  padding-left: 0 !important;
}

.div-block-340-train {
  padding-bottom: 5px;
}

.about-box-train .faq-question-wrap-2 {
  padding-bottom: 0;
}

.about-vox-train .accordion__title__faq-2 {
  padding: 10px !important;
}

.about-box-train .div-block-270 {
  padding: 10px;
}

.link-options-train {
  font-size: 14px !important;
}

.rec-h {
  height: 40px;
}

.outcome-train .image-118 {
  width: 16px;
  height: 16px;
}

.div-block-333 {
  overflow: hidden;
}

.outcome-train .skill-div-2 {
  margin-bottom: 5px;
  clear: both;
}

[dir="ltr"] .outcome-train .skill-div-2 {
  float: left !important;
}

[dir="rtl"] .outcome-train .skill-div-2 {
  float: right !important;
}

.outcome-train {
  overflow: hidden;
}

.textarea-2 {
  height: 250px !important;
  border-radius: 6px;
}

.text-block-187-sum:after {
  animation: blink 1s steps(5, start) infinite;
  content: '▋';
  vertical-align: baseline;
  color: #21232e;
}

[dir="ltr"] .text-block-187-sum:after {
  margin-left: 0.25rem;
}

[dir="rtl"] .text-block-187-sum:after {
  margin-right: 0.25rem;
}

.chat-1.hidden {
  display: none;
}

.div-block-272 {
  z-index: 222;
}

.div-block-329 {
  max-width: 350px;
}

.chat-box.hidden {
  flex-grow: 1;
}

.chat-box.hidden.h-auto {
  height: auto !important;
}

.rel-answer.open .answer-text {
  display: block;
}

.right-content {
  max-height: 100vh;
  overflow: auto;
}

.div-block-325.rel {
  padding-top: 14px;
}

.h-hide {
  display: none !important;
}

.all .grow-flex {
  overflow: auto;
}

.rec-div:hover .div-block-332 {
  display: block;
}

@media screen and (max-width: 767px) {
  .all .grow-flex {
    max-height: 89vh;
  }
}

/* .all .div-block-325.rel{
              position:absolute !important;
          } */

.div-block-294-open:hover > .hidden-img {
  display: none;
}

.div-block-294-open:hover > .hidden-img-rot {
  display: block;
}

.rel-answer.open .answer-text {
  height: auto;
  margin-bottom: 10px;
}

.div-block-294:hover > .image-116 {
  display: none;
}

.div-block-294:hover > .image-93-rot {
  display: inline-block;
}

.rel-row.open .div-block-294 {
  display: none;
}

.rel-row.open .div-block-294-open {
  display: inline-block;
}

.chat-response .form-2 {
  height: 40px;
}

.chat-box.hidden {
  height: 100% !important;
}

.graphs-row {
  overflow: auto !important;
}

.second-input {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .html-embed-2.skill-point.dblock.w-embed.w-script {
    overflow: auto !important;
    min-width: 600px !important;
  }

  .reg-show {
    display: block;
  }

  .form-block-8 {
    padding-top: 0;
  }

  .div-block-280 {
    display: none;
  }

  .div-block-272 {
    position: fixed;
    top: 0;
    width: 100%;
    background: #fff;
  }

  .div-block-52 {
    padding-top: 59px;
  }

  .chat-2 {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #fff;
    margin-bottom: 0;
    padding-bottom: 14px;
  }

  .content-2 {
    padding-bottom: 100px;
  }

  .full.w-bar .chat-box.hidden {
    border-radius: 0;
    border: none !important;
  }

  .body.chat-active {
    overflow: hidden;
  }

  [dir="ltr"] .full.w-bar .image-106.right {
    padding-right: 13px;
  }

  [dir="rtl"] .full.w-bar .image-106.right {
    padding-left: 13px;
  }

  .div-block-325 {
    position: fixed !important;
    background: #fff;
    padding-top: 12px;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    padding-right: 10px;
  }

  .grow-flex {
    padding-bottom: 66px;
  }
  /*.full.w-bar .right-content {
    width: 100% !important;
    overflow: auto;
    padding: 0;
  }*/
  .chat-box {
    min-height: auto;
    height: auto;
    flex-grow: 1;
  }

  .mob-grid {
    margin-left: auto;
    margin-right: auto;
  }

  .resize-img {
    display: none;
  }

  .div-block-326 {
    min-width: auto !important;
  }
}

@media screen and (max-width: 390px) {
  [dir="ltr"] .div-block-275 {
    margin-right: 8px;
  }

  [dir="rtl"] .div-block-275 {
    margin-left: 8px;
  }
}

.with_blink:after {
  animation: blink 1s steps(5, start) infinite;
  content: '▋';
  vertical-align: baseline;
  color: #21232e;
}

[dir="ltr"] .with_blink:after {
  margin-left: 0.25rem;
}

[dir="rtl"] .with_blink:after {
  margin-right: 0.25rem;
}

.no-after:after {
  content: '' !important;
}

@keyframes blink {
  0% {
    display: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Apply the animation to an element */

.blink {
  animation: blink 1s ease-in-out infinite;
}

.text-block-187:after {
  animation: blink 1s steps(5, start) infinite;
  content: '▋';
  vertical-align: baseline;
  color: #21232e;
}

[dir="ltr"] .text-block-187:after {
  margin-left: 0.25rem;
}

[dir="rtl"] .text-block-187:after {
  margin-right: 0.25rem;
}

.full.w-bar .p_menu {
  margin-right: 3% !important;
  margin-left: 3% !important;
  max-width: none !important;
}

.chat-box.active.hidden {
  border-radius: 4px;
}

.full.w-bar .right-content {
  display: flex !important;
  flex-direction: column !important;
}

[dir="ltr"] .full.w-bar .image-116.right .sm {
  margin-left: auto;
}

[dir="rtl"] .full.w-bar .image-116.right .sm {
  margin-right: auto;
}

.full.w-bar .chat-box.hidden {
  display: flex;
}

.full.w-bar .p_menu.w_bar {
  margin-right: 3% !important;
  margin-left: 3% !important;
  max-width: none !important;
}

.full.w-bar .panels_cont {
  margin-right: 3% !important;
  margin-left: 3% !important;
  max-width: none !important;
}

[dir="ltr"] .full.w-bar .main-content {
  padding-right: 380px;
}

[dir="rtl"] .full.w-bar .main-content {
  padding-left: 380px;
}

.full.w-bar .right-content {
  display: block;
}

.full.w-bar .right-content {
  width: 380px;
}

.chat-box {
  width: auto !important;
  border: 1px solid #dde1e6 !important;
}

.about-panels {
  min-height: calc(100vh - 79px - 59px);
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .chatbox.open .open-chat {
    display: none;
  }

  .ch_c {
    margin-bottom: 0;
  }
}

.chatbox {
  padding-bottom: env(safe-area-inset-bottom);
  padding-top: env(safe-area-inset-top);
}

.about-body {
  min-height: calc(100vh - (79px + 58px));
}

input[type='range'] {
  border-radius: 8px;
  height: 7px;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;
}

.fs-rangeslider_track {
  pointer-events: none;
}

.is-pink {
  pointer-events: auto;
}

.menicon {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

[dir="ltr"] .menicon {
  background-image: linear-gradient(90deg, #4a78b8, #6cafe1);
}

[dir="rtl"] .menicon {
  background-image: linear-gradient(-90deg, #4a78b8, #6cafe1);
}

.messages_active .al-m.active {
  margin-bottom: 0;
}

.tw_r.new_m_user.active .user_tick {
  display: block;
}

.body.messages_active .panels {
  padding-bottom: 0;
}

.body.messages_active .panels_cont {
  padding-bottom: 0;
}

.feed-option.active .tw_t {
  font-weight: 500;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}

::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.9);
}

.add_content_btn.disabled {
  opacity: 0.7;
}

.text-block-16 {
  overflow-wrap: break-word;
}

@media screen and (max-width: 1231px) {
  .results {
    grid-template-columns: 1fr 1fr;
  }
}

.inner span {
  position: relative;
  top: 2px;
}

.s_box {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1em;
}

[dir="ltr"] .s_box {
  background-position: right 7px center;
}

[dir="rtl"] .s_box {
  background-position: left 7px center;
}

@media screen and (max-width: 479px) {
  .results {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 1250px) and (min-width: 991px) {
  .slider.w-slider .link-block-7:last-child {
    display: none;
  }

  .div-block-42.sl {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.div-block-78 {
  min-height: calc(100vh - 78px - 70px);
}

@media screen and (max-width: 479px) {
  .div-block-78 {
    min-height: calc(100vh - 78px - 101px);
  }
}

.wday,
.month {
  fill: #6d717f;
}

.float-right {
  color: #6d717f;
}

.more:hover {
  background-color: #fff;
  background-image: none !important;
}

@media screen and (max-width: 479px) {
  [dir="ltr"] .dots {
    left: calc(50% - 19px);
  }

  [dir="rtl"] .dots {
    right: calc(50% - 19px);
  }
}

.chat-box.hidden {
  height: auto !important;
}

@media screen and (max-width: 767px) {
  .chat-box.hidden {
    height: 100% !important;
  }
  /*.full.w-bar .main-content {
    display: none;
  }*/
}

.chat-2 {
  padding-top: 14px;
}

.chat-box.logged-in.hidden {
  display: none;
}

.full.w-bar .nav .p_menu {
  margin-left: 15px !important;
  margin-right: 15px !important;
}
.course-title-top-menu-block{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.course-title-top-menu-block .div-block-63 {
  position: relative;
  z-index: 99999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex
;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  margin-bottom: unset;
  justify-content: end;
}
.course-title-top-menu-block .toolbar-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex
;
  margin-left: 12px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.course-title-top-menu-block .toolbar-div.progress-div{
  position: relative;
  z-index: 999999999999999;
  cursor: pointer;
}
.course-title-top-menu-block .div-block-61 {
  position: relative;
  width: 120px;
  height: 10px;
  border-radius: 4px;
  background-color: #d3d3d3;
}
.course-title-top-menu-block .text-block-49, .course-title-top-menu-block .text-block-44.bl.pp {
  font-size: 14px;
  color: var(--accent);
}
.course-title-top-menu-block .text-block-46 {
  margin-left: 8px;
  font-size: 14px;
  color: var(--accent);
}
.course-title-top-menu-block .progress-hover {
  position: absolute;
  left: auto;
  top: 40px;
  right: 0%;
  bottom: auto;
  z-index: 2;
  display: none;
  width: 100%;
  height: auto;
  padding: 6px 4px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #d2d0d0;
  background-color: #fff;
}
.toolbar-div.grade {
  padding-left: 12px;
  border-right: 1px solid #d2d0d0;
  border-left: 1px solid #d2d0d0;
  color: #0075a8;
}
.toolbar-div.skills-div {
  position: relative;
  margin-left: 0px;
  padding-right: 12px;
  border-right: 1px solid #d2d0d0;
}
.main-content .div-block-630{
  width: 100%;
}
.course-title-top-menu-block .div-block-62 {
  position: absolute;
  width: 60%;
  height: 100%;
  border-radius: 4px;
  background: var(--accent) !important;
}
.course-title-top-menu-block  .secondary_link{
  padding-right: unset!important;
}
.course-title-top-menu-block  .secondary_link.sm.sklls.plus_skills{
  margin-left: unset!important;
}
@media screen and (max-width: 480px) {
  .course-title-top-menu-block.no-grade .div-block-61{
    width: 90%;
  }
}
