.checkbox-label {
    color : #6d717f
}

.greyed-out {
    pointer-events: none; /* Disable all interactions */
    opacity: 0.5; /* Make it look greyed-out */
}

.correct-text-wrap{
    overflow-wrap: anywhere;
}